import React from "react"
import LayoutComponent from "../../../components/documentation-layout/Layout"

const TestCycleActions = () => {
  return (
    <LayoutComponent>
      <h3>More Test Cycle actions</h3>
      <p>
        <strong>Clone Test Cycle</strong>
        <p>
          Cloning a test cycle keeps the associated test cases intact and clears
          all test executions, comments, attachments, and linked issues.
        </p>
        <ol>
          <li>
            Select the test cycle you want to clone, then click on the Clone
            button.
          </li>
          <li>
            If selected test cycle is not completed, a popup will appear. You
            can choose to complete the test cycle and then clone it or choose a
            different purpose for the new test cycle.
          </li>
          <li>
            New test cycle appears in the test-cycle table with "(CLONE)" in the
            title. You can click/open a test cycle to rename it.
          </li>
        </ol>
      </p>
      <br />
    </LayoutComponent>
  )
}

export default TestCycleActions
